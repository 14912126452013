import Link from "next/link";

import { useDispatch, useSelector } from "react-redux";

import User from "../Offcanvas/User";
import { useAppContext } from "@/context/Context";
import { useEffect, useState } from "react";
import { loginSuccess, loginFailure } from "@/redux/action/LoginAction";
import { cartArrCount } from "@/redux/action/CartAction";
import MyModal from "@/components/Modal/MyModal";
import axios from "axios";
import { loadData } from "@/utils/localStorage";
import { useRouter } from "next/router";
import styles from "@/styles/Login.module.css";

const HeaderRightTwo = ({ btnClass, btnText, userType }) => {
  const router = useRouter();
  const dispatch = useDispatch();
  // const { cartCount } = useSelector((state) => state.CartReducer);
  const [cartCount, setCartCount] = useState(0);
  const fetchCarted = async () => {
    axios
      .get(
        `${process.env.NEXT_PUBLIC_BACKEND_GENERIC_URL}/skilling/all-cart-course`,
        {
          params: {
            candid: myData.registration_id,
          },
        }
      )
      .then((res) => {
        setCartCount(res.data.data.length);
      })
      .catch((err) => {
        console.log(err);
        setCartCount(0);
      });
  };
  const { mobile, setMobile, search, setSearch, cartToggle, setCart } =
    useAppContext();
  const [myData, setMyData] = useState(null);

  const { total_items } = useSelector((state) => state.CartReducer);
  const [id, setId] = useState(null);
  const isAuth = useSelector((state) => state.LoginReducer.isAuth);
  useEffect(() => {
    isAuth && setId(true);
    !isAuth && setId(null);
  }, [isAuth]);

  const [dropOpen, setDropOpen] = useState(false);
  const toggleDropdown = () => {
    setDropOpen(!dropOpen);
  };

  //...............Login-Register Modal......................//
  const [open, setOpen] = useState(false);
  const [screen, setScreen] = useState(1);
  // const [cartValue, setCartValue] = false;
  // useEffect(()=>{
  //   sessionStorage.getItem("cart");
  // })

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // console.log(id, isAuth);

  ///Cart
  const [interested, setInterested] = useState([]);
  const user = useSelector((state) => state.LoginReducer.details);
  useEffect(() => {
    setMyData(loadData("user"));
  }, [user]);
  const data = useSelector((state) => state.LoginReducer);
  console.log(data, "user1234");

  const fetchInterested = async () => {
    console.log("myData", myData);
    axios
      .get(
        `${process.env.NEXT_PUBLIC_BACKEND_GENERIC_URL}/skilling/all-interested-course`,
        {
          params: {
            candidate_id: myData?.registration_id,
          },
        }
      )
      .then((res) => {
        setInterested(res.data.data);
        dispatch(cartArrCount(res.data.data.length));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchInterested();
  }, [user]);

  useEffect(() => {
    if (myData !== null) {
      fetchInterested();
      fetchCarted();
    } else {
      setCartCount(0);
    }
  }, [myData]);

  const handleLogout = () => {
    console.log("logged out");
    sessionStorage.clear();
    router.push("/");
    dispatch(loginFailure());
    dispatch(cartArrCount(0));
    window.location.reload();
  };

  useEffect(() => {
    fetchInterested();
  }, []);

  // const handleLogout = () => {
  //   console.log("logged out");
  //   sessionStorage.clear();
  //   dispatch(loginFailure());
  //   dispatch(cartArrCount(0));
  // };

  console.log(cartCount);
  return (
    <div className="header-right">
      <ul className="quick-access">
        {/* <li className="access-icon">
          <Link
            className={`search-trigger-active rbt-round-btn ${
              search ? "" : "open"
            }`}
            href="#"
            onClick={() => setSearch(!search)}
          >
            <i className="feather-search"></i>
          </Link>
        </li> */}

        {myData !== null ? (
          <li className="access-icon rbt-mini-cart">
            <Link
              className="rbt-cart-sidenav-activation rbt-round-btn"
              href="#"
              onClick={() => setCart(!cartToggle)}
            >
              <i className="feather-heart"></i>
              {/* <span className="rbt-cart-count">{interested.length}</span> */}
              {/* <span className="rbt-cart-count">{cartCount}</span> */}
            </Link>
          </li>
        ) : (
          <li
            className="access-icon rbt-mini-cart"
            style={{ cursor: "pointer" }}
          >
            {/* <Link
              className="rbt-cart-sidenav-activation rbt-round-btn"
              href="#"
              onClick={() => setCart(!cartToggle)}
            > */}
            <div
              className="rbt-cart-sidenav-activation rbt-round-btn"
              onClick={handleOpen}
            >
              <i className="feather-heart"></i>
              {/* <span className="rbt-cart-count">0</span> */}
            </div>
            {/* </Link> */}
          </li>
        )}

        {myData !== null ? (
          <li className="access-icon rbt-mini-cart">
            <Link
              className="rbt-cart-sidenav-activation rbt-round-btn"
              href="/cart"
              // onClick={() => setCart(!cartToggle)}
            >
              <i className="feather-shopping-cart"></i>
              {/* <span className="rbt-cart-count">{interested.length}</span> */}
              <span className="rbt-cart-count">{cartCount}</span>
            </Link>
          </li>
        ) : (
          <li
            className="access-icon rbt-mini-cart"
            style={{ cursor: "pointer" }}
          >
            {/* <Link
              className="rbt-cart-sidenav-activation rbt-round-btn"
              href="#"
              onClick={() => setCart(!cartToggle)}
            > */}
            <div
              className="rbt-cart-sidenav-activation rbt-round-btn"
              onClick={handleOpen}
            >
              <i className="feather-shopping-cart"></i>
              <span className="rbt-cart-count">0</span>
            </div>
            {/* </Link> */}
          </li>
        )}

        {id !== null ? (
          <li
            className="account-access rbt-user-wrapper d-none d-xl-block "
            data-toggle="dropdown"
          >
            <Link href="#">
              {/* <div onClick={handleLogout}> */}
              <div onClick={toggleDropdown} className={styles.dropdownButton}>
                <i className="feather-user"></i>
                {myData?.username ? myData?.username : "User"}
              </div>
            </Link>
            {dropOpen && (
              <ul className={styles.dropdownMenu}>
                <li className={styles.dropdownItem} onClick={handleLogout}>
                  {/* <Link href="#" onClick={handleLogout}> */}
                  Logout
                  {/* </Link> */}
                </li>
              </ul>
            )}
          </li>
        ) : (
          <li className="account-access rbt-user-wrapper d-none d-xl-block">
            {/* <Link href="/login">Login</Link> */}
            <div className={`${styles.loginButton}`}>
              <p onClick={handleOpen}>Login</p>
            </div>

            <MyModal open={open} handleClose={handleClose} />
          </li>
        )}
        <div class="dropdown-menu">
          <a class="dropdown-item" href="#" onClick={handleLogout}>
            Logout
          </a>
        </div>

        <li className="access-icon rbt-user-wrapper d-block d-xl-none">
          {id !== null ? (
            <Link className="rbt-round-btn" href="#">
              <i className="feather-user"></i>
            </Link>
          ) : (
            <div className={`${styles.loginButton}`}>
              <p onClick={handleOpen}>Login</p>
            </div>
          )}
          {/* <User /> */}
        </li>
      </ul>

      {/* <div className="rbt-btn-wrapper d-none d-xl-block">
        <Link className={`rbt-btn ${btnClass}`} href="#">
          <span data-text={`${btnText}`}>{btnText}</span>
        </Link>
      </div> */}

      <div className="mobile-menu-bar d-block d-xl-none">
        <div className="hamberger">
          <button
            className="hamberger-button rbt-round-btn"
            onClick={() => setMobile(!mobile)}
          >
            <i className="feather-menu"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default HeaderRightTwo;
